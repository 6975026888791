import dynamic from "next/dynamic";
import styles from "../styles/layoutManager.module.css";
import mainLayout from "../styles/mainLayout.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import AppBar from "src/pujaModule/components/pujaTrackingComponents/AppBar";

const NavBar = dynamic(() => import("../components/navbar"));

const NavbarStatic = dynamic(() => import("../components/navStatic"));

const SmallScreenNavBar = dynamic(() =>
  import("../components/smallScreenNavBar")
);

const Loader = dynamic(() => import("../components/Loader"), {
  ssr: false,
});

const Footer = dynamic(() => import("../components/footer"));

export default function TransitionLayout({ children, router }) {
  const layout = getLayoutNo(router.pathname);
  const smallScreen = useMediaQuery("(max-width:810px)");
  const { t } = useTranslation();

  const getContent = () => {
    switch (layout) {
      case 1:
        return (
          <>
            {/* {!smallScreen ? (
              <NavBar toHome={true} query={router.query} />
            ) : (
              <NavbarStatic />
            )} */}
            <SmallScreenNavBar />
            <NavBar />
            <div
              className={`${mainLayout.full_width}  ${mainLayout.fullWidthNavbar}`}
            >
              {children}
            </div>
          </>
        );

      case 2:
        return (
          <>
            <SmallScreenNavBar />
            <NavBar />
            <div className={`${styles.main}`}>{children}</div>
          </>
        );

      case 3:
        const topMargin =
          // router.pathName === "/testpost" ||
          // router.pathName === "/testpost/[hashtag]" ||
          // router.pathname === "/home" ||
          router.pathname === "/post/[postId]" ||
          router.pathname === "/home/[hashtag]" ||
          router.pathname === "/clip/[postId]";

        return (
          <>
            {smallScreen ? <SmallScreenNavBar /> : <NavBar />}
            <div className={`${styles.main}`}>
              <div
                className={`${
                  router.pathname === "/home"
                    ? mainLayout.posts
                    : mainLayout.posts1
                } `}
                style={{ paddingTop: topMargin ? "60px" : "0px" }}
              >
                {children}
              </div>
            </div>
          </>
        );

      case 4:
        return (
          <>
            <SmallScreenNavBar />
            <NavBar />

            <div className={`${styles.main}`}>
              <div
                className={`${mainLayout.posts} ${mainLayout.full_blog} ${mainLayout.full_width}`}
              >
                {children}
              </div>
            </div>
            <Footer />
          </>
        );

      case 5:
        const topMargin2 =
          // router.pathname === "/home" ||
          router.pathname === "/post/[postId]" ||
          router.pathname === "/home/[hashtag]" ||
          router.pathname === "/clip/[postId]";

        return (
          <>
            <NavBar />
            <div className={`${styles.main}`}>
              <div className={`${mainLayout.posts} ${topMargin2 && "pt-3"}`}>
                {children}
              </div>
            </div>
          </>
        );

      // Puja DETAILS
      case 6:
        return (
          <>
            {(router.pathname === "/puja/[id]/[eventid]" ||
              router.pathname === "/epuja/[id]/[eventid]" ||
              router.pathname === "/fpuja/[id]/[eventid]" ||
              router.pathname === "/puja/[id]") && <NavBar toHome={true} />}

            <div className={`${styles.main_event}`}>
              <div className={`${mainLayout.full_width}`}>{children}</div>
            </div>
            <Footer />
          </>
        );

      case 7:
        return (
          <>
            <SmallScreenNavBar />
            <NavBar />
            <div className={`${styles.main_event}`}>
              <div className={`${mainLayout.full_width_registration}`}>
                {children}
              </div>
            </div>
          </>
        );

      case 8:
        return (
          <>
            <NavBar />
            <div className={`${styles.main_event}`}>{children}</div>
            <Footer />
          </>
        );

      case 9:
        return (
          <>
            <NavBar />
            {children}
          </>
        );

      case 10:
        return (
          <>
            {smallScreen ? (
              <AppBar text={t("review-and-rating")} />
            ) : (
              <NavBar />
            )}

            {children}
          </>
        );

      case 11:
        return (
          <>
            {!smallScreen ? (
              <NavBar toHome={true} query={router.query} />
            ) : (
              <NavbarStatic />
            )}

            <div className={`${mainLayout.full_width}`}>{children}</div>
          </>
        );

      case 12:
        // console.log("layout 12 line 191");
        // console.log("children", children);
        return (
          <>
            {!smallScreen ? (
              <NavBar toHome={true} query={router.query} />
            ) : (
              <NavbarStatic />
            )}

            <div
              className={`${mainLayout.full_width} ${mainLayout.full_video}`}
            >
              {children}
            </div>
          </>
        );

      case 13:
        return (
          <>
            {/* {!smallScreen && <NavBar toHome={true} query={router.query} />} */}
            <div className={`${styles.main_event}`}>
              <div className={`${mainLayout.full_width_registration}`}>
                {children}
              </div>
            </div>
          </>
        );

      case 14:
        return (
          <>
            {smallScreen ? <SmallScreenNavBar /> : <NavBar />}
            <div className={`${mainLayout.full_width}`}>{children}</div>
            {router.pathname !== "/post" && <Footer />}
          </>
        );

      case 15:
        return (
          <>
            {smallScreen ? <AppBar text={t("your-pujas")} /> : <NavBar />}

            <div className={`${mainLayout.full_width}`}>{children}</div>
            <Footer />
          </>
        );
      case 16:
        return (
          <>
            {smallScreen ? <AppBar text={t("puja-status")} /> : <NavBar />}

            <div className={`${mainLayout.full_width}`}>{children}</div>
            <Footer />
          </>
        );

      default:
        return <>{children}</>;
    }
  };

  if (layout === -1) return <>{children}</>;

  const cx = classNames.bind(mainLayout);
  const rootClasses = cx({
    root: layout !== 12 && layout !== 13,
    root_black: layout === 12,
    eventRoot: layout === 13,
  });

  return (
    <div className={`${rootClasses}`}>
      <Loader router={router} />

      {getContent()}

      {/* {layout !== 13 && <PopUpDialog />} */}
    </div>
  );
}

const LAYOUTS = {
  0: ["/blogs/create", "/editblog/[id]"],
  1: [
    "/about",
    "/terms-of-use",
    "/privacy-and-cookie-policy",
    "/content-policy",
    "/refund-policy",
    "/vendor-terms-policy",
  ],
  2: ["/profile/[...params]", "/community/[...params]", "/r/c"], // r/c -> // for https://subdomain.utsavapp.in/
  3: [
    "/clips",
    "/notification",
    "/post/[postId]",
    "/clip/[postId]",
    "/hashtag/[postType]/[hashtag]",
    "/festival-panchang",
  ],
  4: ["/blog/[categoryUrl]/[blogUrl]", "/blogs/[blogUrl]"],
  5: [],
  6: [
    "/puja/[id]/[eventid]",
    "/puja/[id]",
    "/r/e",
    "/epuja/[id]/[eventid]",
    "/fpuja/[id]/[eventid]",
  ],
  7: [
    // "/puja/[id]/all-pujas",
    "/puja/registration/[eventid]/[subeventid]/receipt",
    // "/puja/[id]/[eventid]/registration/success",
    // "/puja/[id]/[eventid]/all-pujas",
  ],
  8: ["/sitemap"],
  9: ["/community/create"],
  10: [
    "/puja/review",
    // '/puja/[id]/[eventid]/[subeventid]/registration/review'
  ],
  11: ["/policies"],
  12: ["/video"],
  13: ["/puja/[id]/[eventid]/[subeventid]", "/puja/registration"],
  14: [
    "/home",
    "/puja",
    "/community",
    "/post",
    "/blogs",
    "/blog/[categoryUrl]",
    "/settings",
    "/puja/benefit/[benefitID]",
  ],
  15: ["/your-puja"],
  16: ["/puja/track"],
};

function getLayoutNo(pathName) {
  for (const [layNo, routes] of Object.entries(LAYOUTS)) {
    if (routes.includes(pathName)) {
      console.log("layout no", layNo);
      return Number(layNo);
    }
  }
  return -1;
}
