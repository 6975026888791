import React, { useContext, useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { LANGUAGE } from "@utils/constants";
import styles from "../styles/nav.module.css";
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import { Store } from "@utils/store/ContextStore";
// Import the i18n instance

const LangugageDropdown = () => {
  // const [cookies, setCookie] = useCookies([]);
  const [langOpen, setLangOpen] = useState(false);
  // const { state } = useContext(Store);
  // const loggedInUser = state.user;
  const router = useRouter();

  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGE.ENGLISH);

  useEffect(() => {
    if (router.locale) {
      // const selectedLang = sessionStorage.getItem("locale");
      // if (selectedLang) {
      //   setSelectedLanguage(selectedLang);
      // } else {
      setSelectedLanguage(router.locale);
      //}
      // setSelectedLanguage(router.locale);
    }
  }, []);

  const languageChangeFunction = async (lang) => {
    setLangOpen(false);
    setSelectedLanguage(lang);
    // i18n.changeLanguage(lang);
    try {
      sessionStorage.setItem("locale", lang);
    } catch (e) {
      console.log("Error: " + e);
    }
    // console.log("arnab", lang, router.asPath);
    window.location.href = `/${lang}${router.asPath}`; // !Problem on this line maybe @arnab
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <Box>
          <Button
            {...bindTrigger(popupState)}
            className={styles.langugageContainer}
          >
            <span>
              {/* {selectedLanguage.charAt(0).toUpperCase() +
                selectedLanguage.slice(1)} */}
              {selectedLanguage === LANGUAGE.ENGLISH ? "En" : "हिं"}
            </span>
            {!popupState.isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                popupState.close();
                languageChangeFunction(LANGUAGE.ENGLISH);
              }}
              className={`${styles.menuText} ${
                selectedLanguage === LANGUAGE.ENGLISH ? styles.activeItem : ""
              }`}
            >
              English
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close();
                languageChangeFunction(LANGUAGE.HINDI);
              }}
              className={`${styles.menuText} ${
                selectedLanguage === LANGUAGE.HINDI ? styles.activeItem : ""
              }`}
            >
              हिंदी
            </MenuItem>
          </Menu>
        </Box>
      )}
    </PopupState>
  );
};

export default LangugageDropdown;
